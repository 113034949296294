import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Send the form data to EmailJS
    emailjs
      .sendForm(
        "service_5z2vsc4",  // Replace with your EmailJS service ID
        "template_v6oof7j",  // Replace with your EmailJS template ID
        e.target,            // The form element
        "z-6sETiQMP1D0T-rM"       // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          setFeedback("Nachricht erfolgreich gesendet!");
          setIsSubmitting(false);
        },
        (error) => {
          console.error("Error sending email:", error.text);
          setFeedback("Es gab einen Fehler beim Senden der Nachricht.");
          setIsSubmitting(false);
        }
      );
  };

  return (
    <section id="contact" className="bg-blue-600 py-16 text-black">
      <h3 className="text-3xl font-bold text-center mb-8 text-white">Kontaktieren Sie uns</h3>
      <div className="container mx-auto px-4">
        <form className="max-w-lg mx-auto" onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Ihr Name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full p-4 mb-4 rounded-lg"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Ihre E-Mail"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-4 mb-4 rounded-lg"
            required
          />
          <textarea
            name="message"
            placeholder="Ihre Nachricht"
            value={formData.message}
            onChange={handleInputChange}
            className="w-full p-4 mb-4 rounded-lg h-32"
            required
          ></textarea>
          <button
            type="submit"
            className="bg-white text-blue-600 px-6 py-3 rounded-lg w-full hover:bg-gray-200 transition"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sende..." : "Nachricht senden"}
          </button>
        </form>
        {feedback && (
          <div className="mt-4 text-center text-lg font-semibold text-white">
            {feedback}
          </div>
        )}
      </div>
    </section>
  );
};

export default ContactForm;
