import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle menu

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState); // Toggle menu visibility
  };

  return (
    <header className="flex justify-between items-center px-4 py-2 bg-black text-white relative">
      <div className="logo text-xl font-bold flex-shrink-0">
        <img src="/logo.png" alt="Logo" className="h-20 w-auto" />
      </div>

      {/* Desktop Menu */}
      <nav className="hidden md:flex space-x-4">
        <a href="#services" className="hover:underline">Dienstlesitungen</a>
        <a href="#portfolio" className="hover:underline">Portfolio</a>
        <a href="#contact" className="hover:underline">Kontakt</a>
      </nav>

      {/* Mobile Menu Button (Hamburger Icon) */}
      <button
        className="md:hidden text-white"
        aria-label="Open Menu"
        onClick={toggleMenu} // Toggle menu on click
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>

      {/* Mobile Menu */}
      <nav
        className={`md:hidden absolute top-20 left-0 right-0 bg-black text-white text-center py-40 z-50 ${isMenuOpen ? 'block' : 'hidden'}`}
      >
        <a href="#services" className="block py-2 hover:underline">Services</a>
        <a href="#portfolio" className="block py-2 hover:underline">Portfolio</a>
        <a href="#contact" className="block py-2 hover:underline">Contact</a>
      </nav>
    </header>
  );
};

export default Header;
 