import React from "react";
import Header from "./components/Header.jsx";
import VideoSection from "./components/VideoSection.jsx";
import ContactForm from "./components/ContactForm.jsx";


const App = () => {
  const services = [
    {
      title: "Corporate Videos",
      description: "Professionelle Videos für Unternehmen und Marken.",
      image: "/corporate.jpg",
    },
    {
      title: "Eventaufnahmen",
      description: "Halten Sie Ihre Events in filmischer Qualität fest.",
      image: "/event.jpg",
    },
    {
      title: "Promotion",
      description: "Auffällige Promos für Social Media und Werbung.",
      image: "/promo.jpg",
    },
    {
      title: "Luftbildaufnahmen",
      description: "Eine Andere Perspektive erleben.",
      image: "/drone.jpg",
    },
  ];

  return (
    <div className="bg-gray-100 font-sans">
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <section
        id="hero"
        className="relative flex items-center justify-center h-screen bg-cover bg-center text-white"
        style={{
          backgroundImage: `url('/cover.jpg')`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Ihr Videograf & Content Creator in Dresden
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Kreative Videoproduktion für Unternehmen, Events und mehr.
          </p>
          <a
            href="#kontakt"
            className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition"
          >
            Kontaktieren Sie uns
          </a>
        </div>
      </section>

      {/* SEO Section */}
      <section id="seo" className="py-16 bg-white">
        <div className="text-center mb-12 px-4">
          <h2 className="text-3xl font-bold mb-4">Videoproduktion Dresden</h2>
          <p className="text-lg text-gray-600">
            Professionelle Videografie und Content Creation in Dresden. Wir sind
            Ihr Partner für Unternehmensvideos, Eventaufnahmen und
            Social-Media-Content. Lassen Sie uns Ihre Vision in beeindruckenden
            Videos festhalten.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-6 md:px-12">
          <div className="p-6 bg-gray-100 shadow-md rounded-lg">
            <h3 className="text-xl font-bold mb-4">Videograf Dresden</h3>
            <p className="text-gray-700">
              Wir bieten professionelle Videografie-Dienstleistungen in Dresden
              an, perfekt für Unternehmen und Privatkunden.
            </p>
          </div>
          <div className="p-6 bg-gray-100 shadow-md rounded-lg">
            <h3 className="text-xl font-bold mb-4">Content Creator Dresden</h3>
            <p className="text-gray-700">
              Kreativer Content, der Ihre Zielgruppe begeistert – von Social
              Media bis hin zu professionellen Werbevideos.
            </p>
          </div>
          <div className="p-6 bg-gray-100 shadow-md rounded-lg">
            <h3 className="text-xl font-bold mb-4">Cutter Dresden</h3>
            <p className="text-gray-700">
              Professionelle Video-Nachbearbeitung und Schnitt für
              eindrucksvolle Ergebnisse.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-16 bg-gray-100">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Unsere Dienstleistungen</h2>
          <p className="text-lg text-gray-600">
            Hochwertige Videoproduktion, die genau auf Ihre Bedürfnisse
            abgestimmt ist.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 px-6 md:px-12">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative group rounded-lg overflow-hidden shadow-lg h-64"
            >
              <div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${service.image})` }}
              ></div>
              <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-40 transition"></div>
              <div className="relative z-10 flex flex-col justify-end h-full p-6 text-white">
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-sm">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <VideoSection/>


      {/* Kontakt Section */}
      <section id="contact" className="bg-blue-600 py-16 text-white">
      <ContactForm/>
      </section>
      <footer class="bg-gray-800 text-white p-4 text-center">
  <p>
    NLmedia – Videoproduktion Dresden<br />
    E-Mail: <a href="mailto:produktion@nlanger.de">produktion@nlanger.de</a><br />
    Telefon: +49 15678 581 874
  </p>
</footer>
    </div>
  );
};

export default App;
