import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const VideoSection = () => {
  const videos = [
    { src: "https://www.youtube.com/embed/WfKuQ6xnMS8", title: "Video 1" },
    { src: "https://www.youtube.com/embed/EZ3M91oF13g", title: "Video 2" },
    { src: "/video1.mp4", title: "Video 3", isLocal: true },
  ];

  return (
    <section id="portfolio" className="bg-gray-50 py-16">
      <h3 className="text-3xl font-bold text-center mb-8">Portfolio</h3>

      {/* Mobile Slider */}
      <div className="md:hidden">
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={20}
          slidesPerView={1}
          className="max-w-screen-lg mx-auto"
          style={{ height: "400px" }}
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index}>
              <div className="relative w-full h-full">
                {video.isLocal ? (
                  <video controls className="absolute inset-0 w-full h-full">
                    <source src={video.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <iframe
                    src={video.src}
                    title={video.title}
                    className="absolute inset-0 w-full h-full"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Desktop Layout */}
      <div className="hidden md:block">
        <div className="flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 w-full max-w-[96%] md:w-[59%]">
            {/* Video 1 */}
            <div className="relative w-full aspect-w-16 aspect-h-9">
              <iframe
                src="https://www.youtube.com/embed/WfKuQ6xnMS8"
                title="Autovideo Car Instagram Reel"
                className="w-full h-full"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* Video 2 */}
            <div className="relative w-full aspect-w-16 aspect-h-9">
              <iframe
                src="https://www.youtube.com/embed/EZ3M91oF13g"
                title="Videoproduktion Dresden – Showreel NLmedia"
                className="w-full h-full"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* Local Video */}
            <div className="relative w-full aspect-w-16 aspect-h-9">
              <video className="w-full h-full" controls>
                <source src="/video1.mp4" type="video/mp4" />
                Your browser does not support this video format.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
